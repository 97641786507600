<template>
  <div class="head_box">
    <imgHead />
    <Header />
    <Companyintro />
    <NavFooter />
  </div>
</template>

<script>
import imgData from "@/data";
import { reactive } from "vue";
import Companyintro from "./components/Companyintro/Companyintro";
import imgHead from "@/views/secondaryHead/compontents/img/imgHead.vue";
import Header from "@/views/secondaryHead/compontents/Header/Header.vue";
import NavFooter from "@/views/secondaryHead/compontents/Footer/Footer.vue";
export default {
  components: { Companyintro, imgHead, Header, NavFooter },
  setup() {
    const data = reactive({
      ...imgData.ContactImg,
    });

    return {
      ...data,
    };
  },
};
</script>

<style scoped>
</style>

